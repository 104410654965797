import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import cardTest from "../../../../../../assets/images/marketPlace/card_test.png";
import dividerImg from "../../../../../../assets/images/teleImage/dividerImg.png";
import {
	getAllMarkePlace,
	getCollectionList,
} from "../../../../../../services/accountApiService";
import CardMarketPage from "../../../../../../commonsUi/modals/CardMarket";

type CardItemType = {
	index: number;
	name: string;
	floorPirce: number;
	price: number;
	img: string;
};

type CardMarketType = {
	collectionSelected: CollectionModel;
	starSelected: number;
	active: string;
};

export default function ListCardMarket({
	collectionSelected,
	starSelected,
	active,
}: CardMarketType) {
	const [dataMarket, setDataMarket] = useState<MarketplaceResponse | null>(
		null
	);
	const [isShowMarketPlace, setShowMarketPlace] = useState(false);
	const [cardSelected, setCardSelected] =
		useState<CardInfoMarketPlace | null>(null);
	const [currency, setCurrency] = useState("apt");

	const getAllItemsMarketPlace = async (
		collectionId?: number,
		starSelected?: number
	) => {
		const resMarket: any = await getAllMarkePlace(
			collectionId,
			starSelected
		);
		setDataMarket(resMarket);
		console.log({ resMarket });
	};

	useEffect(() => {
		console.log({ collectionSelected });

		getAllItemsMarketPlace(
			collectionSelected ? collectionSelected.Id : undefined,
			starSelected !== 0 ? starSelected : undefined
		);
	}, [collectionSelected, starSelected]);

	useEffect(() => {
		getAllItemsMarketPlace();
	}, []);

	useEffect(() => {
		console.log({ active });
		setCurrency(active);
	}, [active]);

	const RenderCardItem = ({
		index,
		name,
		floorPirce,
		price,
		img,
	}: CardItemType) => {
		return (
			<div>
				<div className={style.itemBody}>
					<div
						style={{
							display: "flex",
							alignContent: "center",
							alignItems: "center",
						}}
					>
						<p className={style.indexStyle}>{index}</p>
						<img
							src={img}
							alt="cardTest"
							style={{
								height: "50px",
								marginLeft: "16px",
								marginRight: "10px",
							}}
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignContent: "start",
								alignItems: "start",
								justifyContent: "start",
							}}
						>
							<p className={style.cardName}>{name}</p>
							<div>
								<span className={style.floorPrice}>
									Floor price:
								</span>
								<span
									className={style.valueFloorPrice}
								>{` ${floorPirce.toFixed(2)} ${
									currency === "apt" ? "APT" : "USD"
								}`}</span>
							</div>
						</div>
					</div>
					<div>
						<span
							className={style.valueFloorPrice}
						>{`${price.toFixed(2)} `}</span>
						<span className={style.floorPrice}>
							{currency === "apt" ? "APT" : "USD"}
						</span>
					</div>
				</div>
				{index !== dataMarket?.data.length && (
					<img src={dividerImg} alt="dividerImg" width={364} />
				)}
			</div>
		);
	};

	return (
		<div className={style.main}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginTop: "10px",
				}}
			>
				<p className={style.cardTypeStyle}># Cardtype</p>
				<p className={style.cardTypeStyle}>Volume</p>
			</div>
			<div className={style.custom_scroll}>
				{dataMarket?.data.map((e, index) => (
					<div
						key={index}
						onClick={() => {
							setCardSelected(e);
							setShowMarketPlace(true);
						}}
					>
						<RenderCardItem
							img={e.CardImage}
							index={index + 1}
							name={e.CardName}
							floorPirce={
								currency === "apt"
									? e.floor_price.apt
									: e.floor_price.usdt
							}
							price={
								currency === "apt"
									? e.total_volume.apt
									: e.total_volume.usdt
							}
						/>
					</div>
				))}
			</div>
			{isShowMarketPlace && (
				<CardMarketPage
					onUpdate={() => {}}
					onClose={() => setShowMarketPlace(false)}
					id={cardSelected!.Id}
				/>
			)}
		</div>
	);
}
