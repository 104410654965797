import { isMobile } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";
import ProfileTeleMiniApp from "./components/ProfileTeleMiniApp";
import UnityComponent from "./components/UnityComponent";
import QuestPage, { requestCheckedIn } from "./components/QuestComponent";
import {
	apiPillageJourneyInfo,
	apiRegisterAccountWithAddress,
	requestSkipBuildingApi,
} from "../../../services/claimApiService";
import {
	apiGetMyAccount,
	checkedInRequest,
	requestListCardSyncData,
	requestUpdateProfile,
} from "../../../services/accountApiService";
import { parseEther } from "viem";
import { decodeJwt, encodeJwt, tonToNanoton } from "../../../utils/extension";
import { getRandomString, tabData } from "../../../utils/constants/pages";
import { useLocation } from "react-router-dom";
import IsLandComponent from "./components/IsLand";
import { aptos, MODULE_ADDRESS } from "../../../aptosConfig";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import AptosWalletConnect from "./walletConnect";
import ConfirmTransactionModal from "../../../commonsUi/modals/confirmTransaction/confirmPopUp";
import ConfirmCheckinModal from "../../../commonsUi/modals/confirmCheckin";
import CardPage from "./components/Card/CardPage";
import TeleEnergyShop from "./components/teleEnergyShop/TeleEnergyShop";
import { stavaxAccount } from "../../../connectStavax";
import AccountPage from "./components/Account";
import ConnectWalletModal from "../../../commonsUi/modals/reachUstd";
import RankingPage from "./components/ranking/RankingPage";
import ChoseWallet from "../../../commonsUi/modals/ChooseWallet";

import {
	useAccount,
	useBalance,
	usePublicClient,
	useWriteContract,
} from "wagmi";
import {
	SHOP_WALLET_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../ABI/shopWallet";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useKeylessAccounts } from "../../../core/useKeylessAccounts";
import {
	feePayer,
	feePayer1,
	getLocalKeylessAccount,
	getWalletFromLocalStorage,
	myWalletAddress,
} from "../../../utils/aptos/aptosStorage";
import { devnetClient } from "../../../core/constants";
import PaymentAptosAndTon from "../PaymentAptosTon";
import { WelcomeChest } from "./components/WelcomeChest";
import LoginGame from "./components/Login";
import MarketPlace from "./components/MarketPlace";
import { Account } from "@aptos-labs/ts-sdk";
import { AptosAccount } from "aptos";
import CryptoJS from "crypto-js";
import { JWT_SIGNING } from "../../../utils/constants";
import HamsterComponents from "./components/Hamster";
interface SkipBuildingData {
	buildingId?: string;
	islandId?: string;
}
declare global {
	interface Window {
		Telegram?: {
			WebApp?: {
				ready: () => void;
				expand: () => void;
				initData: string;
				openTelegramLink: (url: string) => void;
				openLink: (
					url: string,
					options?: { try_instant_view?: boolean }
				) => void;
				initDataUnsafe: {
					user: {
						id: number;
						first_name: string;
						last_name?: string;
						username?: string;
						language_code?: string;
						photo_url?: string;
					};
					[key: string]: any;
				};
				HapticFeedback: {
					impactOccurred(style: "light" | "medium" | "heavy"): void;
					notificationOccurred(
						type: "error" | "success" | "warning"
					): void;
					selectionChanged(): void;
				};
				showPopup(params: {
					title?: string;
					message: string;
					buttons?: Array<{
						type: string;
						text?: string;
						id?: string;
					}>;
				}): Promise<string>;
			};
		};
	}
}

const HamsterPage: React.FC = () => {
	const userFriendlyAddress = useTonAddress();
	const { activeAccount, disconnectKeylessAccount } = useKeylessAccounts();
	const [screen, setScreen] = useState(1);
	const [indexTab, setIndexTab] = useState(2);
	const [isUIVisible, setIsUIVisible] = useState(false);
	const [dataRenderHeader, setDataRenderHeader] = useState();
	const [isJoinPillage, setIsJoinPillage] = useState(false);
	const [isClient, setIsClient] = useState(false);
	const [isLoadingFirst, setIsLoadingFirst] = useState(false);
	const [deviceIsMobile, setDeviceIsMobile] = useState(false);
	const [accountInfo, setAccountInfo] = useState(null);
	const [userIdTele, setUserIdTele] = useState<string | string[] | undefined>(
		undefined
	);
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const [isShowConnectWalelt1, setShowConnectWallet1] = useState(false);
	const shareRef = useRef<HTMLParagraphElement>(null);
	const [isCheckedIn, setCheckIn] = useState<string | null>(null);
	const [isClaim, setClaim] = useState(false);
	const [isRecallApi, setRecallApi] = useState(false);
	const location = useLocation();
	const handledEvents = useRef(new Set());
	const [isSkipBuilding, setSkipBuilding] = useState<SkipBuildingData>({});
	const [isShowCheckin, setShowCheckin] = useState(false);
	const [dataBuyEnneryCocos, setDataBuyEnneryCocos] = useState(null);
	const [dataBuyEnneryCocosPremium, setDataBuyEnneryCocosPremium] =
		useState(null);
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const [isBUY_LEGACY, setBUY_LEGACY] = useState(false);
	const { writeContractAsync } = useWriteContract();
	const publicClient = usePublicClient();
	const account = useAccount();
	const [isLogedIn, setLogedIn] = useState(false);
	const [tonConnectUI, setOptions] = useTonConnectUI();
	const [showPreLoading, setShowPreLoading] = useState<boolean>(() => {
		return localStorage.getItem("__showPreLoad") ? false : true;
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const userId: any = params.get("user_id");
		const referId = params.get("refer_id");
		const userName = params.get("user_name");
		setUserIdTele(userId);
		localStorage.setItem("_userId", userId);
		handleLoginWhenWelcome(userId, referId, userName);
	}, [isRecallApi]);

	const handleTransfer = async (id: number, amount: string) => {
		if (account?.address === undefined) {
			const session = await stavaxAccount.connect();
		} else {
			try {
				stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: SHOP_WALLET_CONTRACT_ADDRESS_BASE,
					functionName: "deposit",
					value: parseEther(amount),
					args: [id],
				});
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
			} catch (e) {
				console.log({ e });
			}
		}
	};

	const ethBalance = useBalance({
		address: account?.address,
	});

	const buyItem = async (id: number, usdtPrice: number) => {
		try {
			const bnbUsdtPriceResponse = await fetch(
				"https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT"
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			const value =
				Number(ethBalance?.data?.formatted) * bnbUsdtPriceData.price;
			handleTransfer(id, (usdtPrice / bnbUsdtPriceData.price).toString());
		} catch (error) {
			console.error("Error fetching BNB/USDT price:", error);
		}
	};

	const handleShare = async () => {
		const shareLink = `https://t.me/Slime_Revolution_bot?start=${userIdTele}`;
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: shareLink,
				});
			} catch (error) {}
		}
	};

	const copyTextToClipboard = (text: string) => {
		var textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			var successful = document.execCommand("copy");
			var msg = successful ? "successful" : "unsuccessful";
		} catch (err) {
			console.error("Oops, unable to copy", err);
		}
		document.body.removeChild(textArea);
	};
	useEffect(() => {
		const handleMessage = (event: any) => {
			console.log({ event });
			const eventType = event.data.type;
			if (!handledEvents.current.has(eventType)) {
				handledEvents.current.add(eventType);
				switch (eventType) {
					case "INVITE":
						if (shareRef.current) {
							shareRef.current.click();
						}
						break;
					case "SKIP_BUILDING":
						setSkipBuilding({
							buildingId: event.data.data.buildingId,
							islandId: event.data.data.islandId,
						});
						// skipBuilding(
						// 	event.data.data.buildingId,
						// 	event.data.data.islandId
						// );
						break;
					case "CHECKIN":
						setShowCheckin(true);
						break;
					case "POPUP":
						const title = event.data.title;
						const message = event.data.message;
						window.Telegram?.WebApp?.showPopup({
							title: title,
							message: message,
							buttons: [{ type: "close" }],
						});
						break;
					case "HAPTIC_FEEDBACK":
						window.Telegram?.WebApp?.HapticFeedback.impactOccurred(
							"medium"
						);
						break;
					case "BUY_ENERGY":
						const data = event.data.data;
						if (tonConnectUI.connected) {
							tonConnectUI.disconnect();
						}
						setDataBuyEnneryCocos(data);
						break;
					case "BUY_PREMIUM_WHEEL":
						const dataPrm = event.data.data;
						sendTransaction(dataPrm,false);
						break;

					case "BUY_LEGACY":
						const dataPrm1 = event.data.data;
						setBUY_LEGACY(true);
						sendTransaction(dataPrm1,false);
						break;
					case "BUY_HAMSTER":
						const dataPrm2 = event.data.data;
						sendTransaction(dataPrm2,true);
						break;

					case "CONNECT_WALLET":
						setShowConnectWallet1(true);
						break;
					case "KICK_HAMSTER":
						const count = event.data.data;
						hamterClick(count);
						break;
					case "SPIN":
						sendTransactionAPT();
						break;
					case "CONNECT_TON":
						tonConnectUI.disconnect();
						tonConnectUI.openModal();
						break;

					case "CHANGE_TAB":
						const index = event.data.index;
						setIndexTab(index);
						break;

					case "COPY_TO_CLIPBOARD":
						copyTextToClipboard(
							`https://t.me/Slime_Revolution_bot?start=${userIdTele}`
						);
						break;
					default:
						break;
				}

				setTimeout(() => {
					handledEvents.current.delete(eventType);
				}, 1000);
			}
		};

		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);
	useEffect(() => {
		tonConnectUI.disconnect();
	}, []);

	useEffect(() => {
		if (window.Telegram && window.Telegram.WebApp) {
			const initData = window.Telegram.WebApp.initData;
			const initDataParsed = new URLSearchParams(initData);
			console.log({ initDataParsed });
			console.log("initData", initData);
			const userString = initDataParsed.get("user");
			const startParam = initDataParsed.get("start_param");
			if (userString) {
				console.log({ startParam });
				const user = JSON.parse(userString);
				localStorage.setItem("user_telegramaa", JSON.stringify(user));
				setUserIdTele(user.id);
				localStorage.setItem("_userId", user.id);
				handleLoginWhenWelcome(user.id, startParam, user.username);
			}
		}
	}, [isRecallApi]);

	const handleCallApiPillageJourneyInfoAgain = async () => {
		const responsePillageJourneyInfo: any = await apiPillageJourneyInfo();
		if (
			responsePillageJourneyInfo?.success &&
			responsePillageJourneyInfo?.data?.is_join_pillage === true
		) {
			localStorage.setItem(
				"journey_info",
				JSON.stringify(responsePillageJourneyInfo?.data)
			);
			setIsLoadingFirst(false);
			setScreen(3);
			setIndexTab(3);
			setIsJoinPillage(true);
		} else {
			setIsLoadingFirst(false);
		}
	};

	const handleCallApiInFoUser = async () => {
		const responseInfoFarming: any = await apiGetMyAccount();
		if (responseInfoFarming) {
			setAccountInfo(responseInfoFarming?.data);
			localStorage.setItem(
				"info_data_user",
				JSON.stringify(responseInfoFarming?.data)
			);
		}
	};

	const handleLoginWhenWelcome = async (
		userId: number | string,
		referId: any,
		userName: any
	) => {
		const encryptedPrivateKeyGet = localStorage.getItem(
			"encryptedPrivateKey"
		);
		if (!encryptedPrivateKeyGet) {
			await createAptosNormalAccount();
		}
		localStorage.removeItem("dataInfoFarming");
		localStorage.removeItem("info_data");
		localStorage.removeItem("journey_info");
		localStorage.removeItem("info_data_user");
		const value = {
			TelegramId: userId,
			FirstReferId: referId,
			UserName: userName,
		};

		const payLoadData = await encodeJwt(value);
		const responseRegisterAccount: any =
			await apiRegisterAccountWithAddress({
				value: payLoadData,
			});
		if (responseRegisterAccount?.success) {
			setShowPreLoading(responseRegisterAccount?.data?.first_user);
			if (!responseRegisterAccount?.data?.first_user) {
				localStorage.setItem("__showPreLoad", "1");
			} else {
				localStorage.removeItem("__showPreLoad");
			}
			localStorage.setItem(
				"_gameAccessToken",
				responseRegisterAccount?.data?.access_token
			);
			localStorage.setItem("_AddressAccount", payLoadData);
			localStorage.setItem(
				"info_data",
				JSON.stringify(responseRegisterAccount?.data)
			);
			await handleCallApiPillageJourneyInfoAgain();
			await handleCallApiInFoUser();
			const encryptedPrivateKeyGet = localStorage.getItem(
				"encryptedPrivateKey"
			);
			if (encryptedPrivateKeyGet) {
				await requestUpdateProfile(
					`0x${myWalletAddress()}`,
					"NormalAptos"
				);
			}
			await getListCardSyncData();
			setIsUIVisible(true);
			setIndexTab(2);
			setTimeout(() => {
				setShowConnectWallet(true);
			}, 2000);
		}
	};

	useEffect(() => {
		if (isMobile) {
			setDeviceIsMobile(true);
		} else {
			setDeviceIsMobile(false);
		}
	}, []);

	useEffect(() => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.ready();
			handleExpand();
		}
	}, []);

	const handleExpand = () => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.expand();
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setCheckIn(null);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isCheckedIn]);

	const getListCardSyncData = async () => {
		await requestListCardSyncData();
	};

	const sendTransaction = async (data: any, isHamsterPayment: boolean) => {
		await tonConnectUI.openModal();
		const bnbUsdtPriceResponse = await fetch(
			"https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT"
		);
		const dataDecode: any = await decodeJwt(data!);
		try {
			const hash = await tonConnectUI.sendTransaction({
				validUntil: Math.floor(Date.now() / 1000) + 60,
				messages: [
					{
						address: "UQABldVe8qCRWcUsl7SQhvIzvirHcnikFDVdo1kicZMWm3HS",
						amount: `${tonToNanoton(
							dataDecode!.Price!
						).toFixed(0)}`,
					},
				],
			});
			var iframe: any = document.getElementById("isLandIframe");
			if (isBUY_LEGACY) {
				iframe.contentWindow.postMessage(
					{ type: "BOUGHT_LEGACY", data: hash.boc },
					"*"
				);
			} else if (isHamsterPayment) {
				iframe.contentWindow.postMessage(
					{ type: "BOUGHT_HAMSTER", data: hash.boc },
					"*"
				);
			} else {
				iframe.contentWindow.postMessage(
					{ type: "BUYED_PREMIUM_WHEEL", data: hash.boc },
					"*"
				);
			}
			setBUY_LEGACY(false);
			tonConnectUI.disconnect();
		} catch (e) {
			console.error(e);
		}
	};

	const sendTransactionAPT = async () => {
		try {
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getWalletFromLocalStorage()!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::slime_revolution_game::slime_revolution_spin`,
					functionArguments: [],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getWalletFromLocalStorage()!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			console.log({ response });
		} catch (e) {
			console.log({ e });
		}
	};
	const hamterClick = async (count: number) => {
		try {
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getWalletFromLocalStorage()!.accountAddress,
				data: {
					function: `0x5a586cde731b55191c73718722ab163e9592af4138cf2219bd9a571874fa3813::kick_hamster::kick_hamster`,
					functionArguments: [count],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer1,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getWalletFromLocalStorage()!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			console.log({ response });
		} catch (e) {
			console.log({ e });
		}
	};
	const createAptosNormalAccount = async () => {
		const account = new AptosAccount();
		const encryptedPrivateKey = CryptoJS.AES.encrypt(
			account.toPrivateKeyObject().privateKeyHex,
			JWT_SIGNING
		).toString();
		await requestUpdateProfile(
			account.address()["hexString"],
			"NormalAptos"
		);
		localStorage.setItem("encryptedPrivateKey", encryptedPrivateKey);
	};

	useEffect(() => {
		if (userFriendlyAddress !== "") {
			var iframe: any = document.getElementById("isLandIframe");
			iframe.contentWindow.postMessage(
				{ type: "CONNECTED_TON", data: "CONNECTED_TON" },
				"*"
			);
		}
	}, [userFriendlyAddress]);

	return (
		<div className={styles.main1}>
			{Object.keys(isSkipBuilding).length > 0 && (
				<ConfirmTransactionModal
					noConnect={() => {
						setSkipBuilding({});
						setTimeout(() => {
							setShowConnectWallet(true);
						}, 200);
					}}
					onClose={() => setSkipBuilding({})}
					isLeadId={isSkipBuilding?.islandId}
					buildingId={isSkipBuilding?.buildingId}
				/>
			)}
			{/* {dataBuyEnneryCocosPremium !== null && (
				<PaymentAptosAndTon
					isBUY_LEGACY={isBUY_LEGACY}
					dataPros={dataBuyEnneryCocosPremium}
					onClose={() => {
						setDataBuyEnneryCocosPremium(null);
					}}
				/>
			)} */}
			{isShowCheckin && (
				<ConfirmCheckinModal
					noConnect={() => {
						setSkipBuilding({});
						setTimeout(() => {
							setShowConnectWallet(true);
						}, 200);
					}}
					onClose={() => setShowCheckin(false)}
					statusCheckin={(value) => {
						if (value) {
							setClaim(true);
							setCheckIn("Check in successfully");
						} else {
							setCheckIn("Check in failure");
						}
					}}
				/>
			)}
			{dataBuyEnneryCocos !== null && (
				<ChoseWallet
					dataPros={dataBuyEnneryCocos}
					onClose={() => setDataBuyEnneryCocos(null)}
					onClickStavax={async () => {
						const dataDecode: any = await decodeJwt(
							dataBuyEnneryCocos!
						);
						buyItem(dataDecode!.id!, dataDecode!.price!);
						console.log({ dataDecode });
						setDataBuyEnneryCocos(null);
					}}
					onClickMetatask={() => {
						window.open(
							`https://metamask.app.link/dapp/internaltest.slimerevolution.com/payment?value=${dataBuyEnneryCocos}`,
							"_blank"
						);
						setDataBuyEnneryCocos(null);
					}}
				/>
			)}
			{/* {isShowConnectWallet && (
				<ConnectWalletModal onClose={() => setConnectWallet(false)} />
			)} */}
			<div className={`${styles.main} `}>
				<RWebShare
					data={{
						title: "Kick Hamster",
						text: "Play Kick Hamster with me!",
						url: `https://t.me/kick_hamster_bot/app?start=${userIdTele}`,
					}}
					onClick={() => console.log("shared successfully!")}
				>
					<p ref={shareRef}></p>
				</RWebShare>
				<div
					style={{
						display: indexTab === 2 ? "block" : "none",
					}}
				>
					<HamsterComponents />
				</div>
				{/* <div>
					<>
						<div className={styles.shadowBottom}></div>
						<div className={styles.bottomNavigation}>
							{tabData.map((e, index) => (
								<div
									key={index}
									onClick={() => {
										setIndexTab(index);
									}}
									id="button"
								>
									<img
										src={
											index === indexTab
												? e.imgActive
												: e.imgInActive
										}
										alt={`${e.imgActive}`}
										height={45}
									/>
								</div>
							))}
						</div>
					</>
				</div> */}
			</div>
		</div>
	);
};

export default HamsterPage;
