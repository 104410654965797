import { Account, Ed25519PrivateKey, KeylessAccount } from "@aptos-labs/ts-sdk";
import { JWT_SIGNING } from "../constants";
import CryptoJS from "crypto-js";
import { convertUint8ArrayToHexString } from "../../core/utils";

export const storeKeylessAccount = (account: KeylessAccount): void =>
	localStorage.setItem("@aptos/account", encodeKeylessAccount(account));

export const logOutKeylessAccount = (): void =>
	localStorage.removeItem("@aptos/account");

export const encodeKeylessAccount = (account: KeylessAccount): string =>
	JSON.stringify(account, (_, e) => {
		if (typeof e === "bigint")
			return { __type: "bigint", value: e.toString() };
		if (e instanceof Uint8Array)
			return { __type: "Uint8Array", value: Array.from(e) };
		if (e instanceof KeylessAccount)
			return { __type: "KeylessAccount", data: e.bcsToBytes() };
		return e;
	});

export const getLocalKeylessAccount = (): KeylessAccount | undefined => {
	try {
		const encodedAccount = localStorage.getItem("@aptos/account");
		return encodedAccount
			? decodeKeylessAccount(encodedAccount)
			: undefined;
	} catch (error) {
		console.warn("Failed to decode account from localStorage", error);
		return undefined;
	}
};

export const decodeKeylessAccount = (encodedAccount: string): KeylessAccount =>
	JSON.parse(encodedAccount, (_, e) => {
		if (e && e.__type === "bigint") return BigInt(e.value);
		if (e && e.__type === "Uint8Array") return new Uint8Array(e.value);
		if (e && e.__type === "KeylessAccount")
			return KeylessAccount.fromBytes(e.data);
		return e;
	});

const privateKey = new Ed25519PrivateKey(process.env.REACT_APP_PRIVATE_KEY!);
const privateKey1 = new Ed25519PrivateKey("0xd8953e133c1cb3367d2182dd0ac29232e7f80f15bbf33e3003fc6153cfa1d685");

export const feePayer = Account.fromPrivateKey({ privateKey });
export const feePayer1 = Account.fromPrivateKey({ privateKey:privateKey1 });

export const getWalletFromLocalStorage = (): Account | null => {
	const encryptedPrivateKeyGet = localStorage.getItem("encryptedPrivateKey");
	if (encryptedPrivateKeyGet) {
		const bytes = CryptoJS.AES.decrypt(encryptedPrivateKeyGet, JWT_SIGNING);
		const decryptedPrivateKey = bytes.toString(CryptoJS.enc.Utf8);
		const privateKey1 = new Ed25519PrivateKey(decryptedPrivateKey);
		const account = Account.fromPrivateKey({ privateKey: privateKey1 });
		return account;
	}

	return null;
};
export const getAccountPrivateKey = (): string | null => {
	const encryptedPrivateKeyGet = localStorage.getItem("encryptedPrivateKey");
	if (encryptedPrivateKeyGet) {
		const bytes = CryptoJS.AES.decrypt(encryptedPrivateKeyGet, JWT_SIGNING);
		const decryptedPrivateKey = bytes.toString(CryptoJS.enc.Utf8);
		return decryptedPrivateKey;
	}

	return null;
};
export const myWalletAddress = (): string | null => {
	if (getWalletFromLocalStorage()) {
		return convertUint8ArrayToHexString(
			getWalletFromLocalStorage()!.accountAddress.data
		);
	}

	return null;
};
