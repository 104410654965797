import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useKeylessAccounts } from "../src/core/useKeylessAccounts";
import { jwtDecode } from "jwt-decode";
import { EphemeralKeyPair } from "@aptos-labs/ts-sdk";
import { aptos } from "./aptosConfig";
import useEphemeralKeyPair from "./core/useEphemeralKeyPair";
import { devnetClient } from "./core/constants";
import { storeKeylessAccount } from "./utils/aptos/aptosStorage";
import LoadingCommon from "./commonsUi/CommonUI/LoadingApp";
// import backgroundDark from "./assets/images/teleImage/bgc_sky_full.png";
import backgroundDarkSky from './assets/images/teleImage/bg_sky.png'
import { ThreeDots } from "react-loader-spinner";
function CallbackPage() {
	const isLoading = useRef(false);
	const switchKeylessAccount = useKeylessAccounts(
		(state) => state.switchKeylessAccount
	);
	const keypare = useKeylessAccounts((state) => state.getEphemeralKeyPair);
	const navigate = useNavigate();

	const fragmentParams = new URLSearchParams(
		window.location.hash.substring(1)
	);
	const idToken = fragmentParams.get("id_token");

	//
	const parseJWTFromURL = (url: string): string | null => {
		const urlObject = new URL(url);
		const fragment = urlObject.hash.substring(1);
		const params = new URLSearchParams(fragment);
		return params.get("id_token");
	};

	// window.location.href = https://.../login/google/callback#id_token=...
	const jwt = parseJWTFromURL(window.location.href);
	const payload = jwtDecode<{ nonce: string }>(jwt!);
	const jwtNonce = payload.nonce;
	const ephemeralKeyPair = useEphemeralKeyPair();

	const getAccount = async () => {
		if (jwt != null) {
			const keylessAccount = await aptos.deriveKeylessAccount({
				jwt,
				ephemeralKeyPair,
			});

			storeKeylessAccount(keylessAccount);
		}
	};
	//
	useEffect(() => {
		if (isLoading.current) return;
		isLoading.current = true;

		async function deriveAccount(idToken: string) {
			try {
				await switchKeylessAccount(idToken);
				navigate("/");
			} catch (error) {
				navigate("/");
			}
		}

		if (!idToken) {
			navigate("/");
			return;
		}

		deriveAccount(idToken);
		getAccount();
	}, [idToken, isLoading, navigate, switchKeylessAccount]);

	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				// backgroundImage: `url(${backgroundDark})`,
				backgroundPosition: `center`,
				backgroundRepeat: `no-repeat`,
				backgroundSize: `cover`,
			}}
		>
			<div
				style={{
					width: "400px",
					height: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundImage: `url(${backgroundDarkSky})`,
					backgroundPosition: `center`,
					backgroundRepeat: `no-repeat`,
					backgroundSize: `cover`,
				}}
			>
				<ThreeDots
					visible={true}
					height={"100px"}
					width={"100px"}
					ariaLabel="three-dots-loading"
					radius="9"
					wrapperStyle={{}}
					wrapperClass="vortex-wrapper"
					color="orange"
				/>
			</div>
		</div>
	);
}

export default CallbackPage;
