/** @format */

import style from "./index.module.scss";
import cardImg from "../../../assets/images/teleImage/brgCard.png";
import tagImg from "../../../assets/images/teleImage/tagCard.png";
import buttonCancel from "../../../assets/images/back_icon.png";
import avtImg from "../../../assets/roll/card/Avt.png";
import Reward30 from "../../../assets/images/teleImage/Reward30.png";
import imgT from "../../../assets/roll/card/imgT.png";
import StarRating from "./StartRating";
import { useEffect, useState } from "react";
import {
	collectionsById,
	getIdBurnCard,
	requestBurnCardNft,
} from "../../../services/accountApiService";
import GameButton from "../../commonButton/gameButton/GameButton";
import card1 from "../../../assets/images/card/card2.png";
import ModalCardInfo from "../ModalCardInfo";
import { encodeJwt } from "../../../utils/extension";
import { devnetClient } from "../../../core/constants";
import { MODULE_ADDRESS } from "../../../aptosConfig";
import { feePayer, getWalletFromLocalStorage } from "../../../utils/aptos/aptosStorage";
import ModalExchangeReward from "../exchangeRewardModal";
import LoadingCommon from "../../CommonUI/LoadingApp";
import ModalSlime from "../Modal";

 type BurnCardType = {
	img?: string;
	idCardSelect: number;
	onClose: () => void;
	itemSelected?: CollectionModel;
};
export type AmountType = {
	amount: number;
};

interface BuildProgressProps {
	current: number;
	total: number;
}

export const BuildProgress: React.FC<BuildProgressProps> = ({
	current,
	total,
}) => {
	const percentage =
		total !== 0 ? ((current / total) * 100).toFixed(2) : "0.00";

	return (
		<div className={style.progressBarContainer}>
			<div
				className={style.progressBar}
				style={{
					width: `${percentage}%`,
				}}
			></div>
			<div
				className={style.progressText}
			>{`${current}/${total}`}</div>
		</div>
	);
};
export default function ModalBurnCard({
	img,
	idCardSelect,
	onClose,
	itemSelected,
}: BurnCardType) {
	const [dataCard, setDataCard] = useState<CardModal[] | null>(null);
	const [isEnable, setEnable] = useState(0);
	const [isShowDetail, setShowDetail] = useState(-1);
	const [isShowBurnCard, setShowBurnCard] = useState(false);
	const [isShowLoading, setShowLoading] = useState(false);
	const [isShowLoading1, setShowLoading1] = useState(false);
	const [isShowAlert, setShowAlert] = useState("");
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const [countBurn, setBunrCount] = useState(1);
	const requestGetListCollection = async () => {
		const res: any = await collectionsById(idCardSelect);
		setEnable(res.data.status_trade);
		console.log(res.data);
		setDataCard(res.data.data);
	};

	const requestGetIdBurnCard = async (id: number) => {
		try {
			const idBurnCard: any = await getIdBurnCard(id);
			console.log({ idBurnCard });
			return idBurnCard.data;
		} catch {
			setShowLoading(false);
		}
	};

	const burnCardApiAndContract = async () => {
		setShowLoading(true);
		setShowLoading1(true);
		const idsBurn = await requestGetIdBurnCard(idCardSelect);
		for (let i = 0; i < idsBurn.length; i++) {
			console.log(idsBurn[i]);
			await burnCardSmartContract(idsBurn[i]);
			setBunrCount(i + 1);
		}
		await requestBurnCard(idCardSelect, idsBurn);
		await requestGetListCollection();
		setShowLoading(false);
		setShowLoading1(false);
	};

	useEffect(() => {
		requestGetListCollection();
	}, []);

	const burnCardSmartContract = async (card_token_id: string) => {
		try {
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getWalletFromLocalStorage()!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::slime_nft_slime::burn_aptoslime`,
					functionArguments: [card_token_id],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getWalletFromLocalStorage()!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			console.log({ response });
		} catch (e: any) {
		}
	};

	const requestBurnCard = async (id: number, tokens: string[]) => {
		try {
			const value = {
				collection_id: id,
				list_token: tokens,
			};
			console.log({ value });
			const payLoadData = await encodeJwt(value);
			const resBurn = await requestBurnCardNft({ value: payLoadData });
			console.log({ resBurn });
			if (resBurn !== 404) {
				setShowAlert("Successful reward exchange");
			} else {
				setShowAlert("Redemption failed");
			}
		} catch {
			setShowLoading(false);
		}
	};

	const RenderAmount = ({ amount }: AmountType) => {
		return (
			<div className={style.amountStyle}>
				<p className={style.amountTextStyle}>+{amount}</p>
			</div>
		);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert("");
		}, 2000);
		return () => clearTimeout(timer);
	}, [isShowAlert]);

	const HeaderItem = () => {
		return (
			<div
				className={style.item}
				style={{
					backgroundImage: `url(${card1})`,
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
				}}
			>
				<div className={style.leftItem}>
					<img
						src={itemSelected?.Image}
						alt="avtImg"
						width={200}
						height={200}
						className={style.imgItemStyle}
					/>
					<p className={style.leftItemText}>
						{itemSelected?.CollectionName}
					</p>
				</div>
				<div className={style.rightItem}>
					<p className={style.titleItem}>complete the set to win</p>
					<img
						src={itemSelected?.Rewards.RewardImage}
						alt="Reward30s"
						width={60}
						style={{
							margin: "4px 0",
						}}
						height={18}
					/>
					<BuildProgress
						current={itemSelected!.card_number}
						total={itemSelected!.QuantityCard}
					/>
				</div>
			</div>
		);
	};
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					onClick={onClose}
					src={buttonCancel}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<div className={style.body}>
					{itemSelected ? (
						<HeaderItem />
					) : (
						<div style={{ height: "50px" }}></div>
					)}
					<div className={style.gallery}>
						{dataCard?.map((e, index) => (
							<div
								key={index}
								className={`${style.itemCard} active-btn`}
								onClick={() => {
									setShowDetail(index);
								}}
							>
								<img
									src={e.CardImage}
									alt={`slime-${index}`}
									className={
										e.success === 1
											? style.image
											: style.imageDisable
									}
								/>
								<div className={style.startStyle}>
									{" "}
									<StarRating count={e.Star} />
								</div>
								<p className={style.cardNameStyle}>
									{e.CardName}
								</p>
								<RenderAmount amount={e.number_card} />
							</div>
						))}
					</div>
					<GameButton
						title="Claim Reward"
						onTap={async () => {
							if (isEnable !== 0) {
								setShowBurnCard(true);
							}
						}}
						isDisable={isEnable === 0}
					/>
				</div>
			</div>
			{isShowDetail !== -1 && (
				<ModalCardInfo
					onUpdate={() => requestGetListCollection()}
					indexSelectCard={isShowDetail}
					cards={dataCard!}
					onClose={() => {
						setShowDetail(-1);
					}}
				/>
			)}
			<ModalSlime
				isOpen={isShowLoading1}
				onClose={() => {
					setShowLoading1(false);
				}}
				title="Reward exchange is in progress: "
			>
				<BuildProgress

					current={countBurn}
					total={itemSelected?.QuantityCard ?? 0}
				/>
			</ModalSlime>
			{isShowAlert !== "" && (
				<div className={style.notice1}>{isShowAlert}</div>
			)}
			{img && isShowBurnCard && (
				<ModalExchangeReward
					onClose={() => {
						setShowBurnCard(false);
						console.log("close");
					}}
					img={img!}
					onClaim={async () => {
						burnCardApiAndContract();
					}}
				/>
			)}
		</div>
	);
}
